import React from "react";
import Link from "gatsby-link";
import {
  BiChevronsLeft,
  BiChevronsRight,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import { PageInfo } from "../../types/news-page";
import { twMerge } from "tailwind-merge";

type Pagination = {
  className?: string;
  pageInfo: PageInfo;
  pathname: string;
  isMinimal?: boolean;
};

const Pagination: React.FC<Pagination> = ({
  className = "",
  pageInfo,
  pathname,
  isMinimal = false,
}) => {
  const nextPage: number = pageInfo.hasNextPage
    ? pageInfo.currentPage + 1
    : pageInfo.pageCount;
  const previousPage: number = pageInfo.currentPage - 1;

  return (
    <div
      className={twMerge(
        `flex justify-center gap-x-2 [&_a]:min-w-[32px] [&_a]:min-h-[32px] [&_a]:text-sm [&_a]:font-semibold [&_a]:rounded-[2px] [&_a]:border [&_a]:border-dsc-softgray [&_a]:flex [&_a]:justify-center [&_a]:items-center text-dsc-softgray [&_.active-page]:bg-[#F5F5F5] [&_.active-page]:text-dsc-body ${className}`
      )}
    >
      {!isMinimal && (
        <Link
          aria-disabled={!pageInfo.hasPreviousPage}
          aria-label="First Item"
          className={!pageInfo.hasPreviousPage ? "pointer-events-none" : ""}
          to={`/${pathname}/`}
        >
          <BiChevronsLeft
            size={18}
            className="text-[#C4C4C4] hover:text-dsc-body transition-all duration-300"
          />
        </Link>
      )}
      <Link
        to={`/${pathname}/${previousPage > 1 ? previousPage + "/" : ""}`}
        aria-disabled={!pageInfo.hasPreviousPage}
        aria-label="Previous Item"
        className={!pageInfo.hasPreviousPage ? "pointer-events-none" : ""}
      >
        <BiChevronLeft
          size={18}
          className="text-[#C4C4C4] hover:text-dsc-body transition-all duration-300"
        />
      </Link>
      <Link
        to={`/${pathname}/${
          pageInfo.currentPage !== 1 ? pageInfo.currentPage + "/" : ""
        }`}
        aria-label="Next Item"
        className="active-page"
      >
        {pageInfo.currentPage}
      </Link>
      <Link
        aria-disabled={!pageInfo.hasNextPage}
        className={!pageInfo.hasNextPage ? "pointer-events-none" : ""}
        to={`/${pathname}/${nextPage}/`}
      >
        <BiChevronRight
          size={18}
          className="text-[#C4C4C4] hover:text-dsc-body transition-all duration-300"
        />
      </Link>
      {!isMinimal && (
        <Link
          aria-disabled={!pageInfo.hasNextPage}
          className={!pageInfo.hasNextPage ? "pointer-events-none" : ""}
          to={`/${pathname}/${pageInfo.pageCount}/`}
          aria-label="Last Item"
        >
          <BiChevronsRight
            size={18}
            className="text-[#C4C4C4] hover:text-dsc-body transition-all duration-300"
          />
        </Link>
      )}
    </div>
  );
};

export default Pagination;
