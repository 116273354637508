import React from "react";
import ArchiveTitle from "../../components/common/ArchiveTitle";
import Breadcrumbs from "../../components/common/breadcrumbs/Breadcrumbs";
import FilterByCategoryBadges from "../../components/common/FilterByCategoryBadges";
import Layout from "../../components/common/Layout";
import Pagination from "../../components/common/Pagination";
import NewsCardsListing from "../../components/news/NewsCardsListing";
import Divider from "../../components/common/Divider";
import { graphql } from "gatsby";
import { NewsPageInterFace } from "../../types/news-page";
import Meta from "../../components/common/Meta";

const NewsPage: React.FC<NewsPageInterFace> = ({
  data,
  path,
}: NewsPageInterFace) => {
  const blogData = data?.allStrapiBlog;
  const pageInfo = blogData?.pageInfo;
  const isIndexPage = pageInfo.currentPage == 1;

  const meta = {
    title: `お知らせ｜（株）Digital Stacks${
      isIndexPage ? "" : " - " + pageInfo.currentPage
    }`,
    description: isIndexPage
      ? "サイトやサービスの更新情報、製品などの最新ニュース、イベント、お知らせ、製品発表などの情報をいち早くお届けします。"
      : "",
    pathname: path,
  };

  return (
    <Layout>
      <Meta meta={meta} />
      <Breadcrumbs path="/news" className="mt-4 mb-8" />
      <div className="px-4 lg:px-[100px]">
        <ArchiveTitle
          title="お知らせ"
          shortText="サイトおよびサービスのアップデート情報です。"
        />
        <FilterByCategoryBadges
          isAlignCenter={false}
          categories={data?.allStrapiBlogCategory?.nodes}
          className="pb-10"
          baseSlug="/news"
          totalCount={blogData.pageInfo.totalCount}
        />
        <NewsCardsListing newsData={blogData?.nodes} />
        <Pagination
          pathname={path.replace(/[0-9/]/g, "")}
          pageInfo={blogData.pageInfo}
        />
        <Divider spacing={72} />
      </div>
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query FetchNews($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      skip: $skip
      limit: $limit
      sort: { fields: release_date, order: DESC }
    ) {
      nodes {
        SEO {
          meta_description
          featured_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 186
                  height: 80
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                  webpOptions: { quality: 100 }
                  placeholder: TRACED_SVG
                  layout: FIXED
                )
              }
            }
            url
            alternativeText
          }
        }
        slug
        release_date(formatString: "YYYY.MM.DD ddd")
        title
        blog_categories {
          title
          slug
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
        perPage
      }
    }
    allStrapiBlogCategory {
      nodes {
        slug
        title
      }
    }
  }
`;
