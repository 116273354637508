import { Link } from "gatsby";
import React, { useState } from "react";
import { BsFillTagFill } from "react-icons/bs";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { twMerge } from "tailwind-merge";
import "swiper/css/scrollbar";

type FilterByCategoryBadgesTypes = {
  className?: string;
  baseSlug: string;
  categories: Array<{
    slug?: string;
    title?: string;
    name?: string;
    type_name?: string;
  }>;
  totalCount?: number;
  isAlignCenter?: boolean;
  isTagIconHidden?: boolean;
};

const FilterByCategoryBadges: React.FC<FilterByCategoryBadgesTypes> = ({
  className = "",
  categories,
  baseSlug,
  totalCount,
  isTagIconHidden = false,
  isAlignCenter = true,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const swipeTo = (index) => swiper?.slideTo(index);

  return (
    <div
      className={twMerge(
        `flex items-center gap-x-4 lg:gap-x-8 [&_a]:flex [&_a]:justify-center [&_a]:items-center [&_a]:gap-x-1 [&_a]:text-xs [&_a]:leading-[14px] [&_a:not(.active-badge)]:text-dsc-softgray [&_.active-badge]:text-dsc-title [&_.active-badge]:px-[10px] [&_.active-badge]:py-[5px] [&_.active-badge]:border [&_.active-badge]:border-dsc-softgray [&_.active-badge]:bg-dsc-white-smoke [&_.active-badge]:rounded-[12px] font-bold [&_a]:whitespace-nowrap [&_.swiper-slide]:w-auto [&_.swiper-slide]:min-h-[75px] [&_.swiper-slide]:flex  [&_.swiper-slide]:items-center [&_a:hover]:transition-all [&_a:hover]:duration-500 [&_a:hover]:text-dsc-darkshade ${className}`
      )}
    >
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        className={`w-full ${
          isAlignCenter && "[&_.swiper-wrapper]:md:justify-center"
        }`}
        onSwiper={setSwiper}
        speed={1000}
        modules={[Scrollbar]}
        scrollbar={{ draggable: true }}
      >
        <SwiperSlide>
          <Link
            to={`${baseSlug}/`}
            getProps={(props) => {
              return (
                props.location.pathname.replace(/[1-9/]/g, "") ==
                  baseSlug.replace("/", "") && { className: "active-badge" }
              );
            }}
            className={"whitespace-nowrap"}
          >
            {!isTagIconHidden && <BsFillTagFill />}
            {`ALL${totalCount ? " ( " + totalCount + " )" : ""} `}
          </Link>
        </SwiperSlide>
        {categories?.map((category, index) => {
          const badgeLink = category?.slug
            ? `/${category.slug}/`
            : `${baseSlug + "/" + category.type_name}/`;
          return (
            <SwiperSlide key={index}>
              <Link
                to={badgeLink}
                key={index}
                getProps={(props) => {
                  props.isPartiallyCurrent && swipeTo(index);
                  return (
                    props.isPartiallyCurrent && { className: "active-badge" }
                  );
                }}
              >
                {category?.title || category?.type_name}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default FilterByCategoryBadges;
