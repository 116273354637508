import React from "react";
import { NewsCard } from "../common/NewsCards";

type NewsCardsListingType = {
  className?: string;
  newsData: Array<object>;
};

const NewsCardsListing: React.FC<NewsCardsListingType> = ({
  className,
  newsData,
}) => {
  return (
    <div className={`mb-6 md:mb-10 ${className}`}>
      {newsData.map((news, index) => (
        <NewsCard news={news} key={index} />
      ))}
    </div>
  );
};

export default NewsCardsListing;
