import React from "react";

type ArchiveTitleType = {
  title: string;
  shortText: string | React.ReactNode;
  className?: string;
};

const ArchiveTitle: React.FC<ArchiveTitleType> = ({
  title,
  shortText,
  className = "",
}) => {
  return (
    <div className={className}>
      <h1 className="dsc-h1-lg uppercase font-bold font-sub mb-[6px] leading-[60px] text-dsc-title">
        {title}
      </h1>
      <div className="text-base mb-14 max-w-[950px]">
        {typeof shortText === "string" ? (
          <p>{shortText}</p>
        ) : (
          <div>{shortText}</div>
        )}
      </div>
    </div>
  );
};

export default ArchiveTitle;
